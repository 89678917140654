import { navigate } from "gatsby"
import React from "react"
import Layout from "../components/layout"
import { AboutContent } from "./about"
import Carousel from "../components/carousel"
import { testimonials } from "../data/testimonials"

export default function Home() {
  return (
    <Layout>
      <React.Fragment>
        <section className="flex justify-content-center-space-between align-items-center m-y-3 halves">
          <section className="bordered left">
            <h1 className="m-b-2">GET BLOG CONTENT FOR YOUR HEALTH & WELLNESS BUSINESS</h1>
            <p>Choose from three writing packages to share your message of health and well-being with the world.</p>
            <button className="cta m-t-1" onClick={() => navigate('/work-with-me')}>Get Started!</button>
          </section>

          <img className="p-x-3" src="/assets/images/alex-homepage-full.jpg"></img>
        </section>

        <AboutContent></AboutContent>

        <section className="m-t-3">
          <div className="flex">
            <section className="bordered top m-a">
              <h1 className="text-center">TESTIMONIALS</h1>
            </section>
          </div>

          <div className="m-y-2">
            <Carousel items={testimonials} />
          </div>
        </section>
      </React.Fragment>
    </Layout>
  )
}
