import React, { useEffect, useState } from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { shuffle } from "../utils";

export type CarouselItem = {
  title?: string;
  subTitles?: string[];
  link?: { href: string, label: string };
  body: string;
}

export default function Carousel({ items }: { items: CarouselItem[] }) {
  const settings = {
    dots: true,
    infinite: true,
    speed: 250,
    slidesToShow: 2,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 5000,
    pauseOnHover: true,
    responsive: [
      {
        breakpoint: 800,
        settings: {
          slidesToShow: 1,
        }
      }
    ]
  };

  const [randomItems, setRandomItems] = useState(items);
  useEffect(() => {
    setRandomItems(shuffle([...items]));
  }, []);

  return (
    <Slider {...settings}>
      {randomItems?.map((item, idx) => (
        <section key={idx} className="p-x-2">
          {item.title && <h4 className="bold">{item.title}</h4>}
          {item.subTitles && item.subTitles.map((subTitle, idx2) => <div key={`_${idx2}`}>{subTitle}</div>)}
          {item.link && <a href={item.link.href}>{item.link.label}</a>}
          <p>
            {item.body}
          </p>
        </section>
      ))}
    </Slider>
  );
}
