import { CarouselItem } from "../components/carousel";

export const testimonials: CarouselItem[] = [{
  title: 'Vashti Kanahele, MS, CHHC',
  subTitles: ['Functional Medicine Practitioner'],
  link: { href: 'https://greenpapayahealth.com/', label: 'Green Papaya Health' },
  body: 'I hired Alexandra to write blog posts for my website, and I couldn’t be happier. Alexandra writes well-researched and scientific yet approachable blogs for me each month. Not only have I saved time and energy by outsourcing to Alexandra, but my SEO has improved, and I have more visitors to my website. Alexandra submits the posts early, clearly communicates her ideas, and responds quickly to any questions I have. I highly recommend working with Alexandra.',
}, {
  title: 'Leonila Campos, MBA, RD',
  subTitles: ['Clinical Dietitian/Online Nutrition Vegan Coach', 'Owner & Founder of Fueled by Leo'],
  body: 'It has been amazing working with Alexandra. She has gone above and beyond my expectations. She is creating amazing guides for my business that I would not have been able to create on my own. Alexandra uses science-based research to do her work. This is something I sincerely appreciate as a dietitian, as I have to provide research-based and accurate information to my clients. I highly recommend Alexandra for any writing projects you have or anything that she can help you with. I am extremely happy and pleased with her work.',
}, {
  title: 'Hanna Rakowska, RDH, RHN, CHCP, BHS',
  subTitles: ['Registered Holistic Nutritionist & Certified Holistic Cancer Practitioner'],
  link: { href: 'https://www.backtobalancenutrition.com/', label: 'Back to Balance Nutrition' },
  body: 'I cannot say enough about how impressed I am with your work, professionalism, and integrity of your work. The article is absolutely perfect. Thank you so much! Your testimonials say it all, but I have to say that they are one hundred percent accurate!',
}, {
  title: 'Jeanette Davis, CHNC, BComm, BA',
  subTitles: ['Certified Holistic Nutritional Consultant & Metabolic Balance® Coach'],
  link: { href: 'https://adishofwellness.com/', label: 'A Dish of Wellness' },
  body: 'I hired Alexandra to write a blog post for my website when I needed additional help. She was very easy to work with and made suggestions for topics that were appropriate to my audience. Her content was clear, easy to understand, and scientifically researched. I appreciated the Instagram and Pinterest posts that went along with the blog post. I will definitely use Alexandra again when I need the support for my blog.',
}, {
  title: 'Astrid Boeckelmann, ND',
  link: { href: 'https://drastridnd.com/', label: 'Naturopathic Functional Medicine Doctor' },
  body: 'It has been an absolute pleasure to work with Alex. She easily turns snapshot ideas into in-depth blog articles and well-designed social media posts. I marvel at Alex’s knowledge about functional medicine topics (I usually learn from her articles!) and I am super impressed by her level for detail, providing links to sub-pages on my personal website, and making sure that typography and style match my own designs - just to name a few. Could not be happier with her awesome work!',
}, {
  title: 'Haley Schroth, RDN, LD, CPT, RYT',
  subTitles: ['Registered Dietitian Nutritionist'],
  link: { href: 'https://thefulfilledfork.com/', label: 'The Fulfilled Fork' },
  body: "As a registered dietitian nutritionist who provides evidence-based information to my clients, quality research is the cornerstone of my business and content. I know that I can trust Alex to do thorough research and make even the most complicated topics easy to understand! If I had to choose, Alex's best qualities are creativity, attention to detail, and her impeccable writing skills. I also applaud Alex for her research skills. Now that we've been working together for several months, I can confidently say that, for me, this is one of the best investments I have made in my business!",
}];
